// extracted by mini-css-extract-plugin
export var column = "CountryEdit__column__SZwJI";
export var container = "CountryEdit__container__p0dPN";
export var flex = "CountryEdit__flex__pQG6m";
export var flexColumn = "CountryEdit__flexColumn__GPG9g";
export var gap1 = "CountryEdit__gap1__RhJm5";
export var gap2 = "CountryEdit__gap2__KkzhA";
export var gap3 = "CountryEdit__gap3__HWRRk";
export var gap4 = "CountryEdit__gap4__k5kBD";
export var gap5 = "CountryEdit__gap5__p6pQn";
export var pageTitle = "CountryEdit__pageTitle__yB0rO";
export var row = "CountryEdit__row__veUiZ";